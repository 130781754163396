<template>
  <div class="address_box">
     <div class="title">
        <img @click="goblack" src="../../../../src/assets/image/black.png" alt="">
        <span>收货地址</span>
     </div>
     <div>
        <van-address-list
            :switchable="false"
            :list="list"
            default-tag-text="默认"
            @add="onAdd"
            @edit="onEdit"
            />
     </div>
     <van-empty v-if="list.length == 0" description="暂无收货地址" />
    <bottomList></bottomList>
  </div>
</template>

<script>
import { getAddressList } from '@/api/order'
export default {
  name: "addresss",

  data() {
    return {
        chosenAddressId: '1',
      list: [
      ]
    };
  },

  mounted() {
    this.getList()
  },

  methods: {
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    // 获取收货地址列表
    getList() {
        getAddressList().then(res => {
            res.data.forEach(item => {
              this.list.push({
                    id: item.id,
                    name: item.name,
                    tel: item.phone,
                    address: item.area + ' ' + item.details,
                    isDefault: item.isDefault,
                    area: item.area,
                    details: item.details,
                    code: item.code
                })
            });
        })
    },
    onAdd() {
        this.$router.push({
            name: `editAddress`,
            params: {
                id: 0
            }
        })
    },
    onEdit(item, index) {
        this.$router.push({
            name: `editAddress`,
            params: {
                id: item.id,
                obj: JSON.stringify(item)
            }
        })
    },
  },
};
</script>

<style lang="less" scoped>
.title{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}
.van-tag--danger{
    background: #000;
    border-radius: 2rem;
}
 /deep/ .van-radio__icon--checked .van-icon{
    background-color: #000;
    border-color: #000;
}
.van-address-list__bottom{
    border-top: none;
}
.van-address-list__bottom .van-button {
    background-color: #E0BFB1;
    border-color: #E0BFB1;
    border-radius: 4rem;
}
.van-address-item{
    border-bottom: 1rem solid #F6F7FA;
}
</style>
